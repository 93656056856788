<template>
  <div v-if="info" class="book-info">
    <div class="book-info-item">
      <v-icon small>$vuetify.icons.star</v-icon>
      {{ info.count_likes }}
    </div>
    <div class="book-info-item">
      <v-icon small>$vuetify.icons.bookshelf</v-icon>
      {{ info.count_libraries }}
    </div>
    <div class="book-info-item">
      <v-icon small>$vuetify.icons.comment_text_outline</v-icon>
      {{ info.count_comments }}

      <span
        class="error--text"
        v-if="+info.count_comments > +info.last_count_comments"
      >
        +{{ +info.count_comments - +info.last_count_comments }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["info"],
  data() {
    return {};
  },
  methods: {},
};
</script>
