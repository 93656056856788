<template>
  <div v-if="book" class="book-statistics">
    <p v-if="+book.finish" class="success--text finish">
      <v-icon dense size="14" color="success">$vuetify.icons.check</v-icon
      >Полный текст
    </p>
    <p v-else class="vpocess">
      В процессе:
      <span>{{ book.create_book_date }}</span>
    </p>

    <!-- <p class="success--text book-price">
      {{ +book.paid && book.price ? book.price + " руб." : "Бесплатно" }}
    </p> -->
    <p>
      Обновлено:
      <span>{{ book.last_update }}</span>
    </p>
    <p>
      Общий размер:
      <span>{{ book.count_pages + '('+ book.count_char +' симв.)' }}</span>
    </p>
    <p v-if="+book.adult">
      Ограничение:
      <span>18+</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["book"],
  data() {
    return {};
  },
  methods: {},
};
</script>
