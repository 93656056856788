<template>
  <div class="reader-nav pa-4" v-if="chapters && pos">
    <v-btn
      v-if="pos > 1"
      @click.stop="prevChapter"
      class="mr-2"
      color="white"
      dense
      >Пред. часть</v-btn
    >

    <v-btn
      v-if="pos < chapters.length"
      @click.stop="nextChapter"
      class="ml-2"
      color="white"
      dense
      >След. часть</v-btn
    >
  </div>
</template>
<script>
export default {
  props: ["chapters", "pos"],
  data: function () {
    return {};
  },
  methods: {
    goChapter(chapter) {
      this.$emit("go-chapter", chapter);
      // console.log(chapter);
    },
    prevChapter() {
      if (this.pos > 1) {
        let chapter = this.chapters[this.pos - 2];
        this.goChapter(chapter);
      }
    },
    nextChapter() {
      if (this.pos < this.chapters.length) {
        let chapter = this.chapters[this.pos];

        this.goChapter(chapter);
      }
    },
  },
  watch: {},
  mounted() {},
  // computed: {
  //   current_page(){
  //     return this.page;
  //   }
  // }
};
</script>