<template>
  <div class="pa-4" v-if="pages && page">
    <v-pagination
      v-if="pages"
      @input="goPage"
      v-model="current_page"
      :length="pages"
      :total-visible="$vuetify.breakpoint.smAndUp ? 7 : 5"
    ></v-pagination>
  </div>
</template>
<script>
export default {
  props: ["pages", "page"],
  data: function () {
    return {
      current_page: this.page,
    };
  },
  methods: {
    goPage() {
      this.$emit("go-page", this.current_page);
    },
  },
  watch: {
    page() {
      this.current_page = this.page;
    },
  },
  // computed: {
  //   current_page(){
  //     return this.page;
  //   }
  // }
};
</script>