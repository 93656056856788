<template>
  <!-- <v-toolbar tile flat elevation="1">
      <v-btn @click="play" class="reader-icon px-0" outlined>
        <v-icon v-if="!played">$vuetify.icons.play_circle</v-icon>
        <v-icon v-else>$vuetify.icons.pause_circle</v-icon>
      </v-btn>
      <v-btn @click="cancel" class="reader-icon px-0" outlined>
        <v-icon v-if="!played">$vuetify.icons.play_circle</v-icon>
        <v-icon v-else>$vuetify.icons.pause_circle</v-icon>
      </v-btn>
    </v-toolbar> -->
  <v-slide-y-transition>
    <div class="speech-navigation" v-show="played" tile flat elevation="1">
      <v-list v-if="initVoices" :dark="darkTheme">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="px-2">Голос</v-list-item-title>
            <!-- :class="{ 'theme--dark': darkTheme }"
            :style="{ color: textColorTheme, background: colorTheme }"
            :dark="darkTheme"
            :color="colorTheme" -->
            <!-- :style="{ color: textColorTheme, background: colorTheme }" -->
            <v-select
              :dark="darkTheme"
              :color="colorTheme"
              :menu-props="{ contentClass: 'reader'}"
              v-if="voices.length > 0"
              :items="voices"
              item-text="name"
              return-object
              v-model="voice"
              label="Выберите голос"
              @change="setVoice"
              solo
            ></v-select>
            <!-- solo
         item-text="nameVoice"
          item-value="value"
          :dark="darkTheme"
          :color="colorTheme"
          @change="setVoice" -->
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="px-2">Позиция</v-list-item-title>
            <v-slider
              v-model="speechPosePercent"
              min="0"
              max="100"
              @change="setPose"
            >
              <template v-slot:append>{{ speechPosePercent }}%</template>
            </v-slider>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="px-2">Скорость</v-list-item-title>
            <v-slider
              v-model="rate"
              min="0.1"
              max="10"
              step="0.1"
              @change="setRate"
            >
              <template v-slot:append>x{{ rate }}</template>
            </v-slider>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else class="text-center">
        В данном браузере функция недоступна. Попробуйте открыть книгу в другом
        браузере.
      </div>
    </div>
    <!-- <template v-slot:extension>
          <div>Навигация</div>
        </template> -->
  </v-slide-y-transition>
</template>
<script>
// import VueTextToSpeech from "vue-text-to-speech";
import EasySpeech from "easy-speech";
import NoSleep from 'nosleep.js';
export default {
  components: {
    // VueTextToSpeech,
  },
  props: [
    "text",
    "bookId",
    "chapterId",
    "darkTheme",
    "colorTheme",
    "textColorTheme",
  ],
  data: () => ({
    lang: "ru-RU",
    noSleep: null,
    // played: false,
    startSpeechText: 0,
    speechPose: 0,
    speechPosePercent: 0,
    rate: 1,
    played: false,
    speaked: false,
    initVoices: false,
    voice: null,
    voiceName: null,
    voices: [],
    // posIndex: 0,
  }),
  methods: {
    play() {
      //Если воспроизводится
      if (this.played) {
        this.played = false;
        this.noSleep.disable();
        this.cancel();
      } else {
        this.played = true;
        this.noSleep.enable();
        this.speak();
      }
    },
    speak() {
      if (!this.initVoices) {
        return;
      }
      // Функция речи
      // let self = this;
      // console.log(this.voice);

      EasySpeech.speak({
        text: this.speechText,
        voice: this.voice, // optional, will use a default or fallback
        pitch: 1,
        rate: this.rate,
        volume: 2,
        // there are more events, see the API for supported events
        // boundary: (e) => {
        //   // console.log(e.charIndex)
        //   this.updatePoseBySpeech(e.charIndex);
        // },
        end: () => {
          this.updatePoseIndexBySpeech();
        },
        start: () => {
          this.startSpeak();
        },
      });
    },
    startSpeak() {
      this.speaked = true;
    },
    initData() {
      //Получение данных из памяти
      this.voiceName = localStorage.getItem("voice");
      let speechRate = +localStorage.getItem("speechRate");
      if (speechRate) {
        this.rate = speechRate;
      }
      let speechId = localStorage.getItem("speechId");
      let parentSpeechId = this.bookId + "_" + this.chapterId;
      let pose = +localStorage.getItem("speechPoseA");
      if (pose >= this.text.length || speechId != parentSpeechId) {
        pose = 0;
        this.speechPosePercent = 0;
      }
      this.speechPose = pose;
      this.updatePercent();

      // this.startSpeechText = this.posIndex;

      localStorage.setItem("speechId", parentSpeechId);

      EasySpeech.init({ maxTimeout: 5000, interval: 250 })
        .then(() => {
          // console.log(EasySpeech.voices());
          let voices = EasySpeech.voices();
          let currentVoice = null;
          for (var i = 0; i < voices.length; i++) {
            if (voices[i].lang == "ru_RU" || voices[i].lang == "ru-RU") {
              if (this.voiceName == voices[i].name) {
                currentVoice = voices[i];
              }
              this.voices.push(voices[i]); // Ставим язык как параметр
            }
          }
          this.initVoices = true;
          if (currentVoice) {
            this.voice = currentVoice;
          } else {
            this.voice = this.voices[0];
          }

          // console.log(this.voices);
        })
        .catch((e) => console.error(e));
      // console.log("cleanText.length", this.cleanText.length);
      // console.log("startSpeechText", this.startSpeechText);
    },
    reload() {
      this.cancel();
      this.speak();
    },
    cancel() {
      this.speaked = false;
      EasySpeech.cancel();
    },
    setRate() {
      localStorage.setItem("speechRate", this.rate);
      this.reload();
    },
    setPose() {
      let newSpeechPose = Math.floor(
        (this.text.length / 100) * this.speechPosePercent
      );

      if (newSpeechPose >= this.text.length) {
        this.speechPose = this.text.length - 1;
      } else {
        this.speechPose = newSpeechPose;
      }
      // console.log("speechPose", this.speechPose)
      // console.log("this.text.length", this.text.length)
      this.savePose();
      this.reload();
    },
    setVoice() {
      localStorage.setItem("voice", this.voice.name);
      this.cancel();
      this.speak();

      // console.log(this.voice);
    },
    // updatePoseBySpeech(index) {
    //   this.speechPose = Math.floor(
    //     ((this.startSpeechText + index) * 100) / this.cleanText.length
    //   );
    //   this.savePose();
    // },
    updatePoseIndexBySpeech() {
      if (!this.played || !this.speaked) {
        // console.log("NOT SPEAKED");
        return;
      }
      // console.log(e);
      // console.log("this.speaked !!!!", this.speaked);
      let newSpeechPose = this.speechPose + 1;
      if (newSpeechPose < this.text.length) {
        // this.posIndex  = newPosIndex;
        this.speechPose = newSpeechPose;

        this.updatePercent();
        this.savePose();
        this.speak();
      }
    },
    savePose() {
      localStorage.setItem("speechPoseA", this.speechPose);
    },
    updatePercent() {
      this.speechPosePercent = Math.floor(
        (this.speechPose * 100) / this.text.length
      );
    },
  },
  computed: {
    speechText() {
      //Отсекаем от очищенного текста с позиции и до конца

      return this.text[this.speechPose];
      // return this.cleanText.substring(this.posIndex) + "Глава окончена";
    },
    // startSpeechText(){
    //   console.log("startSpeechText", this.cleanText.length - this.posIndex)
    //   return this.cleanText.length - this.cleanText.substring(this.posIndex);
    // },
    cleanText() {
      // return this.text.replace(/<\/?[^>]+(>|$)/g, "");
      return this.text;
    },
  },
  watch: {
    text() {
      this.played = false;
      this.initData();
      this.cancel();
    },
  },
  mounted() {
    this.noSleep = new NoSleep();
    this.played = false;
    this.initData();

    this.cancel();

    // console.log("Mounted",this.text[0])

    // window.speechSynthesis.getVoices();
    window.addEventListener("unload", this.cancel());
  },
  beforeDestroy() {
    this.played = false;
    this.cancel();
    this.noSleep.disable();
    window.removeEventListener("unload", this.cancel());
  },
};
</script>